// Angular
import { Injectable } from '@angular/core';
// RxJS
import { BehaviorSubject } from 'rxjs';
declare var $: any;
declare var KTBlockUI: any;


@Injectable()
export class KtDialogService {
  //private ktDialog: any;
  private blockUI: any;
  private currentState: BehaviorSubject<boolean> = new BehaviorSubject(false);

  // Public properties
  constructor() {
    let target = document.querySelector("#kt_app_content_container");
    this.blockUI = new KTBlockUI(target, {
      message: '<div class="blockui-message"><span class="spinner-border text-primary"></span> Loading...</div>'
    });
  }

  show() {
    if (!this.blockUI.blocked)
      this.blockUI.block();
  }

  hide() {
    /*$("#kt_app_content_container").removeClass("blockui");*/
    if (this.blockUI.blocked)
      this.blockUI.release();
  }

  checkIsShown() {
    return this.currentState.value;
  }
}
