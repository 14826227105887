import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { DynamicAsideMenuService } from '../../../';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidebar-menu',
  templateUrl: './sidebar-menu.component.html',
  styleUrls: ['./sidebar-menu.component.scss']
})
export class SidebarMenuComponent implements OnInit {
  //
  menuConfig: any;
  subscriptions: Subscription[] = [];
  constructor(private menu: DynamicAsideMenuService, private cdr: ChangeDetectorRef, private router: Router) { }

  ngOnInit(): void {
    // menu load
    const menuSubscr = this.menu.menuConfig$.subscribe(res => {
      this.menuConfig = res;
      this.cdr.detectChanges();
    });
    this.subscriptions.push(menuSubscr);
  }

}
