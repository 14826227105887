import { Injectable, Inject } from '@angular/core';
import { ReplaySubject, Observable } from 'rxjs';
import { DOCUMENT } from '@angular/common';
declare var $: any;

@Injectable()
export class ScriptLoaderService {
  loadedLibraries: { [url: string]: ReplaySubject<void> } = {};

  constructor(@Inject(DOCUMENT) private readonly document: Document) { }

  loadScript(url: string): Observable<void> {

    const script = this.document.createElement('script');
    script.type = 'text/javascript';
    script.src = url;
    if (this.loadedLibraries[url]) {
      $('script[src="' + url + '"]').remove();
      /*return this.loadedLibraries[url].asObservable();*/
    }

    this.loadedLibraries[url] = new ReplaySubject();

    script.onload = () => {
      this.loadedLibraries[url].next();
      this.loadedLibraries[url].complete();
    };

    this.document.body.appendChild(script);

    return this.loadedLibraries[url].asObservable();
  }
}
