import { Routes } from '@angular/router';

const Routing: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  //{
  //  path: 'builder',
  //  loadChildren: () => import('./builder/builder.module').then((m) => m.BuilderModule),
  //},
  //{
  //  path: 'crafted/pages/profile',
  //  loadChildren: () => import('../modules/profile/profile.module').then((m) => m.ProfileModule),
  //  // data: { layout: 'light-sidebar' },
  //},
  //{
  //  path: 'crafted/account',
  //  loadChildren: () => import('../modules/account/account.module').then((m) => m.AccountModule),
  //  // data: { layout: 'dark-header' },
  //},
  //{
  //  path: 'crafted/pages/wizards',
  //  loadChildren: () => import('../modules/wizards/wizards.module').then((m) => m.WizardsModule),
  //  // data: { layout: 'light-header' },
  //},
  //{
  //  path: 'crafted/widgets',
  //  loadChildren: () => import('../modules/widgets-examples/widgets-examples.module').then((m) => m.WidgetsExamplesModule),
  //  // data: { layout: 'light-header' },
  //},
  //{
  //  path: 'apps/chat',
  //  loadChildren: () => import('../modules/apps/chat/chat.module').then((m) => m.ChatModule),
  //  // data: { layout: 'light-sidebar' },
  //},
  //{
  //  path: 'apps/users',
  //  loadChildren: () => import('./user/user.module').then((m) => m.UserModule),
  //},
  //{
  //  path: 'apps/roles',
  //  loadChildren: () => import('./role/role.module').then((m) => m.RoleModule),
  //},
  //{
  //  path: 'apps/permissions',
  //  loadChildren: () => import('./permission/permission.module').then((m) => m.PermissionModule),
  //},

  //
  {
    path: 'procurement/purchaseorder/addpurchaseorder',
    loadChildren: () => import('./procurementcomponents/purchaseorder/addpurchaseorder/addPurchaseOrder.module').then(m => m.AddPurchaseOrderModule)
  },
  {
    path: 'procurement/purchaseorder/addpurchaseorder/:id',
    loadChildren: () => import('./procurementcomponents/purchaseorder/addpurchaseorder/addPurchaseOrder.module').then(m => m.AddPurchaseOrderModule)
  },
  {
    path: 'procurement/purchaseorder/fetchpurchaseorder',
    loadChildren: () => import('./procurementcomponents/purchaseorder/fetchpurchaseorder/fetchPurchaseOrder.module').then(m => m.FetchPurchaseOrderModule)
  },
  //
  {
    path: 'procurement/purchaseinvoice/addpurchaseinvoice',
    loadChildren: () => import('./procurementcomponents/purchaseinvoice/addpurchaseinvoice/addPurchaseInvoice.module').then(m => m.AddPurchaseInvoiceModule)
  },
  {
    path: 'procurement/purchaseinvoice/addpurchaseinvoice/:id',
    loadChildren: () => import('./procurementcomponents/purchaseinvoice/addpurchaseinvoice/addPurchaseInvoice.module').then(m => m.AddPurchaseInvoiceModule)
  },
  {
    path: 'procurement/purchaseinvoice/fetchpurchaseinvoice',
    loadChildren: () => import('./procurementcomponents/purchaseinvoice/fetchpurchaseinvoice/fetchPurchaseInvoice.module').then(m => m.FetchPurchaseInvoiceModule)
  },
  //
  {
    path: 'sales/deliverynoteschedule/adddeliverynoteschedule',
    loadChildren: () => import('./salescomponents/deliverynoteschedule/adddeliverynoteschedule/addDeliveryNoteSchedule.module').then(m => m.AddDeliveryNoteScheduleModule)
  },
  {
    path: 'sales/deliverynoteschedule/adddeliverynoteschedule/:id',
    loadChildren: () => import('./salescomponents/deliverynoteschedule/adddeliverynoteschedule/addDeliveryNoteSchedule.module').then(m => m.AddDeliveryNoteScheduleModule)
  },
  {
    path: 'sales/deliverynoteschedule/fetchdeliverynoteschedule',
    loadChildren: () => import('./salescomponents/deliverynoteschedule/fetchdeliverynoteschedule/fetchDeliveryNoteSchedule.module').then(m => m.FetchDeliveryNoteScheduleModule)
  },
  //
  {
    path: 'sales/salesorder/addsalesorder',
    loadChildren: () => import('./salescomponents/salesorder/addsalesorder/addSalesOrder.module').then(m => m.AddSalesOrderModule)
  },
  {
    path: 'sales/salesorder/addsalesorder/:id',
    loadChildren: () => import('./salescomponents/salesorder/addsalesorder/addSalesOrder.module').then(m => m.AddSalesOrderModule)
  },
  {
    path: 'sales/salesorder/fetchsalesorder',
    loadChildren: () => import('./salescomponents/salesorder/fetchsalesorder/fetchSalesOrder.module').then(m => m.FetchSalesOrderModule)
  },
  //
  {
    path: 'sales/salesinvoice/addsalesinvoice',
    loadChildren: () => import('./salescomponents/salesinvoice/addsalesinvoice/addSalesInvoice.module').then(m => m.AddSalesInvoiceModule)
  },
  {
    path: 'sales/salesinvoice/addsalesinvoice/:id',
    loadChildren: () => import('./salescomponents/salesinvoice/addsalesinvoice/addSalesInvoice.module').then(m => m.AddSalesInvoiceModule)
  },
  {
    path: 'sales/salesinvoice/fetchsalesinvoice',
    loadChildren: () => import('./salescomponents/salesinvoice/fetchsalesinvoice/fetchSalesInvoice.module').then(m => m.FetchSalesInvoiceModule)
  },

  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: 'error/404',
  },
];

export { Routing };
